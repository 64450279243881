import HeroBreadcrumb from "../components/HeroBreadcrumb"
import * as React from "react"
import {Link, graphql, PageProps} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

type DataProps = {
    site: {
        buildTime: string
    }
}

const Exercises: React.FC<PageProps<DataProps>> = ({
                                                       data,
                                                       path,
                                                       location,
                                                   }) => {
    const posts = (data as any).allMarkdownRemark.nodes
    const filteredPosts = posts.filter(p => !!p.frontmatter.isExercise || !!p.frontmatter.exerciseTitle);
    return (
        <Layout location={location} title={"Exercises and workout routines"}>
            <Seo title="All posts"/>
            <HeroBreadcrumb pageTitle="Our guides on how to do the best and most effective exercises"/>

            <div style={{maxWidth: "700px", margin: "40px auto"}}>
                <p>Here is our list of exercise guides. We update it regularly, so come back soon for more!</p>
                <ol style={{listStyle: `none`}}>
                    {
                        filteredPosts.map(
                            link => <li key={link.fields.slug}><Link to={`${link.fields.slug}`}>{link.frontmatter.exerciseTitle || link.frontmatter.title}</Link></li>
                        )
                    }
                </ol>
            </div>
        </Layout>
    );
}

export default Exercises

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          isExercise
          exerciseTitle
          title
          description
        }
      }
    }
  }
`
