import React from 'react';

export default function HeroBreadcrumb(props) {
  const title = props.pageTitle || "View blog post"
  const style = {
    // backgroundColor: "black",
    // background: "url(/edgar-chaparro-sHfo3WOgGTU-unsplash.jpg)",
  }

  return (
      <div className="site-breadcrumb" style={style}>
        <div className="container">
          <h2 className="breadcrumb-title">{title}</h2>
          {/* <ul className="breadcrumb-menu">
            <li><a href="index.html">Home</a></li>
            <li className="active">Blog Single</li>
          </ul> */}
        </div>
      </div>
  )
}
